import * as PIXI from "pixi.js";
import {
    loadProgressHandler, randomInt, keyboard, hitTestRectangle, hitTestSphere,
    randomCountByDay, Item, generateString
} from "./utility";
import {ICollectable, CollectableSprite} from './collectableSprite';

import paperImage from "./images/paper.png";
import personImage from "./images/person.png";
import canImage from "./images/can.png";
import { MoveSprite } from "./moveSprite";
import { is, toExpression } from "@babel/types";
import { start } from "repl";
import { red } from "color-name";

let loader: PIXI.Loader;
let person: MoveSprite;
let message : PIXI.Text;
let debugMode: boolean;
let days: Item[][] = [];
let items: Item[] = [];
let gameOn = true;

const WHITE = 0xFFFFFF;
const BLACK = 0x000000;

export class Main {
    private static readonly GAME_WIDTH = 1334;
    private static readonly GAME_HEIGHT = 750;
    private static readonly GAME_BOTTOM = 650;

    private t: PIXI.RenderTexture;
    private dayText: PIXI.Text = new PIXI.Text("");
    private day = 1;
    private app: PIXI.Application;
    private papers: CollectableSprite[] = [];
    private cans: CollectableSprite[] = [];
    private debugMessages: PIXI.Text[] = [];
    private enemies: MoveSprite[] = [];
    private score: number = 0;
    private started: boolean = false;
    private exitNode1: PIXI.Sprite = new PIXI.Sprite();
    private exitNode2: PIXI.Sprite = new PIXI.Sprite();
    private entranceNode: PIXI.Sprite  = new PIXI.Sprite();

    private startScreen: PIXI.Container;
    private gameScreen: PIXI.Container;
    private endScreen: PIXI.Container;
    private timeleft: number = 30;

    private health = 100;
    private NUM_ENEMIES = 50;

    /*
    0 start menu
    1 in game day
    2 between game day
    */

    constructor() {
        window.onload = (): void => {
            this.startLoadingAssets();
        };
        debugMode = false;
        this.startScreen = new PIXI.Container();
        this.gameScreen = new PIXI.Container();
        this.endScreen = new PIXI.Container();
        this.app = new PIXI.Application({
            backgroundColor: WHITE,
            width: Main.GAME_WIDTH,
            height: Main.GAME_HEIGHT,
        });

        const p = new PIXI.Graphics();
        p.beginFill(0x739c28);
        p.drawCircle(20, 20, 20);
        p.endFill();

        this.t = PIXI.RenderTexture.create({width: p.width, height: p.height});
        this.app.renderer.render(p, this.t);

    }

    private startLoadingAssets(): void {
        loader = PIXI.Loader.shared;
        loader.add("paper", paperImage);
        loader.add("person", personImage);
        loader.add("can", canImage);
        loader.on("progress", loadProgressHandler)

        loader.on("complete", () => {
            this.onAssetsLoaded();
        });
        //
        loader.load();
    }

    private onAssetsLoaded(): void {
        document.body.appendChild(this.app.view);
        this.onResize();
        window.addEventListener("resize", this.onResize.bind(this));

        this.buildStartScreen(this.app);

        this.buildGameScreen(this.app);

        this.buildEndScreen(this.endScreen);
        this.app.stage.addChild(this.endScreen);

        this.transition(0,0);

        this.app.ticker.add(delta => this.gameLoop(delta));
        setInterval(this.decreaseTime.bind(this), 1000);
    }

    private buildStartScreen(app: PIXI.Application) {
        let t = new PIXI.Text(" Move with arrow keys");
        let t2 = new PIXI.Text(" Shop for groceries before the time runs out");
        t2.y = 40;
        let t3 = new PIXI.Text("Start");
        t3.position = new PIXI.Point(400, 200);
        this.startScreen.addChild(t, t2, t3);
        app.stage.addChild(this.startScreen);

        t3.interactive = true;
        t3.on("click", () => { this.transition(0, 1); });
    }

    private showEndScreen(container: PIXI.Container){
        container.visible = true;
        this.dayText.text = "Day: " + this.day + "\n" + "HP: " + this.health + "\n\n" + "Total Items:\n" + generateString(days);
    }

    private transition(start: number, end: number){
        this.startScreen.visible = false;
        this.gameScreen.visible = false;
        this.endScreen.visible = false;

        if(end === 0)
            this.startScreen.visible = true;
        if (end === 1) 
            this.gameScreen.visible = true;
        if (end === 2) 
            this.showEndScreen(this.endScreen);
    }

    private buildEndScreen(container: PIXI.Container) {
        let t3 = new PIXI.Text("Next Day");
        t3.y = 200;
        t3.x = 400;

        container.addChild(this.dayText, t3);
        t3.interactive = true;
        t3.on("click", () => {
            this.day++;
            this.health = Math.min(this.health + 10, 100);
            this.resetBoard();
            this.transition(2, 1);
        });
    }

    private buildGameScreen(app: PIXI.Application) {
        this.populateExit(app);
        this.populatePlayer(app);

        this.makeControls();

        this.populatePaper(app);
        this.populateCan(app);
        this.populateEnemies(app);
        this.makeMisc(app);

        app.stage.addChild(this.gameScreen);
        this.gameScreen.visible = false;
    }


    private resetBoard() {
        this.timeleft = 30;
        gameOn = true;
        this.entranceNode.tint = WHITE;
        this.started = false;
        person.scale.set(1);
        person.x = Main.GAME_WIDTH / 2 - person.width / 2;
        person.y = Main.GAME_HEIGHT - person.height/2 - 10;
        person.scale.set(.5);
        this.populatePaper(this.app);
        this.populateCan(this.app);
        this.populateEnemies(this.app);
    }

    private makeMisc(app: PIXI.Application): void {
        let o = keyboard("o");
        o.press = () => {
            debugMode = !debugMode;
        }
        let p = keyboard("p");
        p.press = () => {
            this.transition(0, 1);
        }
        let r = keyboard("r");
        r.press = this.resetBoard.bind(this);
        message = new PIXI.Text("");
        message.y = Main.GAME_BOTTOM;
        this.gameScreen.addChild(message);
    }

    private buildBetweenDayScreen(app: PIXI.Application): void {
        app.stage.removeChildren();
        this.populatePaper(app);
    }

    private populatePlayer(app: PIXI.Application): void {
        person = new MoveSprite(PIXI.Texture.from("person"), 0, 0);
        person.x = Main.GAME_WIDTH / 2 - person.width / 2;
        person.y = Main.GAME_HEIGHT - person.height/2 - 10;
        person.scale.set(.5);
        this.gameScreen.addChild(person);
    }

    private makeControls(): void {
        let left = keyboard("ArrowLeft"),
            up = keyboard("ArrowUp"),
            right = keyboard("ArrowRight"),
            down = keyboard("ArrowDown");

        //Left arrow key `press` method
        left.press = () => {
            //Change the person's velocity when the key is pressed
            person.vx = -5;
            person.vy = 0;
        };

        //Left arrow key `release` method
        left.release = () => {
            //If the left arrow has been released, and the right arrow isn't down,
            //and the person isn't moving vertically:
            //Stop the person
            if (!right.isDown && person.vy === 0) {
                person.vx = 0;
            }
        };

        //Up
        up.press = () => {
            person.vy = -5;
            person.vx = 0;
        };
        up.release = () => {
            if (!down.isDown && person.vx === 0) {
                person.vy = 0;
            }
        };

        //Right
        right.press = () => {
            person.vx = 5;
            person.vy = 0;
        };
        right.release = () => {
            if (!left.isDown && person.vy === 0) {
                person.vx = 0;
            }
        };

        //Down
        down.press = () => {
            person.vy = 5;
            person.vx = 0;
        };
        down.release = () => {
            if (!up.isDown && person.vx === 0) {
                person.vy = 0;
            }
        };
    }

    
    private populateExit(app: PIXI.Application): void {
        const p = new PIXI.Graphics();
        p.beginFill(0x228B22);
        p.drawRect(0,0,100,100);
        p.endFill();

        p.beginFill(0x00FF00);
        p.drawPolygon([
            new PIXI.Point(40,10),
            new PIXI.Point(40,50),
            new PIXI.Point(10,50),
            
            new PIXI.Point(50,90),

            new PIXI.Point(89,50),
            new PIXI.Point(59,50),
            new PIXI.Point(59,10),

            new PIXI.Point(40,10),
        ]);
        p.endFill();

        const t = PIXI.RenderTexture.create({width: p.width, height: p.height});
        app.renderer.render(p, t);
        let exit = new PIXI.Sprite(t);
        exit.x = 400;
        exit.y = Main.GAME_BOTTOM;
        this.exitNode1 = exit;
        this.gameScreen.addChild(exit);

        let exit2 = new PIXI.Sprite(t);
        exit2.x = 800;
        exit2.y = Main.GAME_BOTTOM;
        this.exitNode2 = exit2;
        this.gameScreen.addChild(exit2);

        const t2 = PIXI.RenderTexture.create({width: p.width, height: p.height, scaleMode: 12});
        this.entranceNode = new PIXI.Sprite(t);
        let entrance = this.entranceNode;
        entrance.x = 600;
        entrance.y = Main.GAME_BOTTOM;
        entrance.anchor.set(0, 1);
        entrance.scale.y = -1;
        this.gameScreen.addChild(entrance);
    }

    private populateCan(app: PIXI.Application): void {
        let count = randomCountByDay(10, this.day);
        this.cans.forEach(element => {
            this.gameScreen.removeChild(element);
            element.destroy();
        });
        this.cans = [];
        let canTexture = PIXI.Texture.from("can");
        for (let i = 0; i < count; i++) {
            let can = new CollectableSprite(canTexture);
            this.cans.push(can);
            can.scale.set(0.3);
            can.x = randomInt(10, Main.GAME_WIDTH - can.width -10);
            can.y = randomInt(10, Main.GAME_BOTTOM - can.height -10);
            this.gameScreen.addChild(can);
        }
    }

    private populatePaper(app: PIXI.Application): void {
        let count = randomCountByDay(10, this.day);
        this.papers.forEach(element => {
            this.gameScreen.removeChild(element);
            element.destroy();
        });
        this.papers = [];
        let paperTexture = PIXI.Texture.from("paper");
        for (let i = 0; i < count; i++) {
            let paper = new CollectableSprite(paperTexture);
            this.papers.push(paper);
            paper.scale = new PIXI.Point(.5, .5);
            paper.x = randomInt(10, Main.GAME_WIDTH - paper.width -10);
            paper.y = randomInt(10, Main.GAME_BOTTOM - paper.height -10);
            this.gameScreen.addChild(paper);
        }
    }
    private populateEnemies(app: PIXI.Application): void {
        let m  = Math.min(this.day ** 1.5 + 4, this.NUM_ENEMIES);
        let count = randomInt(m, m * 1.5);

        this.enemies.forEach(element => {
            this.gameScreen.removeChild(element);
            element.destroy();
        });
        this.enemies = [];
        for (let i = 0; i < count; i++) {
            let paper = new MoveSprite(this.t);
            paper.vx = randomInt(-1.5, 1.5);
            paper.vy = randomInt(-1.5, 1.5);
            var isValid = new Boolean(false);
            // up to 10 spawn tries
            for (let k = 0; k < 10; k++) {
                let valid = false;
                paper.x = randomInt(0, Main.GAME_WIDTH - paper.width);
                paper.y = randomInt(0, Main.GAME_BOTTOM - paper.height);
                for(let j = 0; j < i; j++)
                {
                    if (!hitTestSphere(this.enemies[j], paper, 56)) {
                        valid = true;
                        break;
                    }
                }
                if (valid) break;
            }
            this.enemies.push(paper);
            this.gameScreen.addChild(paper);
        }
    }
    private onResize(): void {
        if(!this.app){return}
        let xscale = window.innerWidth / Main.GAME_WIDTH;
        let yscale = window.innerHeight / Main.GAME_HEIGHT;
        let finalscale = Math.min(xscale, yscale)

        this.app.renderer.resize(window.innerWidth, window.innerHeight);
        this.app.stage.scale.x = finalscale;
        this.app.stage.scale.y = finalscale;
    }

    gameLoop(delta: any) {
        if (!gameOn) {
            return;
        }

        if (this.health < 0 || this.timeleft < 0){
            gameOn = false;
            let t = new PIXI.Text("GAME OVER");
            t.scale.set(4);
            t.x = Main.GAME_WIDTH/2 - t.width/2
            t.y = Main.GAME_HEIGHT/2 - t.height/2
            this.gameScreen.addChild(
                t
            )
        }

        person.x = person.x + person.vx;
        person.y = person.y + person.vy;
        // check if we are hitting an entrance or exit
        if (!this.started && hitTestRectangle(person, this.entranceNode)) {
        }
        else if (hitTestRectangle(person, this.exitNode1) || hitTestRectangle(person, this.exitNode2))
        {
            gameOn = false;
            let papercount = 0;
            this.papers.forEach(element => {
                if(element.isCollected && element.isCollectedByFriendly){
                    papercount++;
                }
            });
            items.push(new Item("paper", papercount));

            let cancount = 0;
            this.cans.forEach(element => {
                if(element.isCollected && element.isCollectedByFriendly){
                    cancount++;
                }
            });
            items.push(new Item("can", cancount))
            days.push(items);
            items = [];
            console.log("Total: \n" + generateString(days));
            this.transition(1, 2);
        }
        else {
            if (!this.started) {
                this.started = true;
                this.entranceNode.tint = 0xdd3300;
            }
            if (person.x < 0) { person.x = 0}
            if (person.y < 0) { person.y = 0}
            if (person.x + person.width > Main.GAME_WIDTH) { person.x = Main.GAME_WIDTH - person.width}
            if (person.y + person.height > Main.GAME_BOTTOM) { person.y = Main.GAME_BOTTOM - person.height}
        }


        this.papers.forEach(element => {
            if (!element.isCollected && hitTestRectangle(element, person)){
                element.isCollected = true;
                element.visible = false;
            }
        });
        this.cans.forEach(element => {
            if (!element.isCollected && hitTestRectangle(element, person)){
                element.isCollected = true;
                element.visible = false;
            }
        });

        for (let i = 0; i < this.enemies.length; i++) {
            let element = this.enemies[i];

            this.papers.forEach(element => {
                if (!element.isCollected && hitTestRectangle(element, this.enemies[i])){
                    element.isCollected = true;
                    element.visible = false;
                    element.isCollectedByFriendly = false;
                }
            });
            this.cans.forEach(element => {
                if (!element.isCollected && hitTestRectangle(element, this.enemies[i])){
                    element.isCollected = true;
                    element.visible = false;
                    element.isCollectedByFriendly = false;
                }
            });


            element.y += element.vy;
            element.x += element.vx;
            if (element.y < 0 || element.y +element.height > Main.GAME_BOTTOM){
                element.vy *= -1
                element.y += element.vy;
            }
            if (element.x < 0 || element.x +element.width > Main.GAME_WIDTH){
                element.vx *= -1
                element.x += element.vx;
            }
            // bounce the enemies on each other
            this.enemies.forEach(e2 => {
                if (e2 != element) {
                    if (hitTestSphere(element, e2, 28.28)){
                        element.vy *= -1
                        element.vx *= -1
                        element.y += element.vy;
                        element.x += element.vx;
                        e2.vy *= -1
                        e2.vx *= -1
                        e2.y += e2.vy;
                        e2.x += e2.vx;
                    }
                }
            })
            if (hitTestRectangle(element, person)){
                this.health--;
                person.tint = 0xff0000;
            }
        }

        // draw status info
        message.text = " Day: " + this.day + "\n" + " Time Left: " + this.timeleft + "\n" + " HP: " + this.health;
        if (debugMode) {
            for (let i = 0; i < this.enemies.length; i++) {
                let dt;
                if (this.debugMessages.length === i){
                    dt = new PIXI.Text("");
                    this.debugMessages.push(dt);
                    this.app.stage.addChild(dt);
                }
                else {
                    dt = this.debugMessages[i];
                }
                dt.x = this.enemies[i].x;
                dt.y = this.enemies[i].y;
                dt.text = "vx: " + this.enemies[i].vx + " vy: " + this.enemies[i].vy + " " + this.enemies[i].x + " " + this.enemies[i].y + " " + this.enemies[i].width + " " + this.enemies[i].height;
            }
            message.text = "Score: " + this.score + "\n" + "person: " + person.x + "," + person.y + "\n" + Math.round(PIXI.Ticker.shared.FPS) + " fps";
        }
        else {
            if(this.debugMessages.length > 0)
            {
                this.debugMessages.forEach(element => {
                    element.destroy();
                });
                this.debugMessages = [];
            }
        }

    }
    private decreaseTime(){
        this.timeleft--;
    }
}

new Main();