import { Loader, LoaderResource } from "pixi.js";

//The `randomInt` helper function
function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


function loadProgressHandler(loader: Loader, resource: LoaderResource) {
  //Display the file `url` currently being loaded
  console.log("loading: " + resource.url);

  //Display the percentage of files currently loaded
  console.log("progress: " + loader.progress + "%");

  //If you gave your files names as the first argument 
  //of the `add` method, you can access them like this
  //console.log("loading: " + resource.name);
}

class Item {
  count: number = 0;
  readonly name: string = 'unknown';

  constructor(name: string, count: number){
      this.name = name;
      this.count = count;
  }
}

function generateString(days: Item[][]): string {
  let a = new Map();
  let i = 0;
  days.forEach(day => {
      i++;
      day.forEach(item => {
          console.log("Day: " + i + " " + item.name + " " + item.count);
          if (a.has(item.name))
              a.set(item.name, item.count + a.get(item.name))
          else
              a.set(item.name, item.count)
      });
  });
  let ret = "";
  for (let [key, value] of a) {
      ret += key + ' = ' + value + '\n'
  };
  return ret;
}


class keyClass {
  value: any;
  isDown: boolean = false;
  isUp: boolean = false;
  press: any;
  release: any;
  downHandler: any;
  upHandler: any;
  unsubscribe: any
}

function keyboard(value: any) {
  let key = new keyClass()
  key.value = value;
  key.isDown = false;
  key.isUp = true;
  key.press = undefined;
  key.release = undefined;
  //The `downHandler`
  key.downHandler = (event: any) => {
    if (event.key === key.value) {
      if (key.isUp && key.press) key.press();
      key.isDown = true;
      key.isUp = false;
      event.preventDefault();
    }
  };

  //The `upHandler`
  key.upHandler = (event: any) => {
    if (event.key === key.value) {
      if (key.isDown && key.release) key.release();
      key.isDown = false;
      key.isUp = true;
      event.preventDefault();
    }
  };

  //Attach event listeners
  const downListener = key.downHandler.bind(key);
  const upListener = key.upHandler.bind(key);

  window.addEventListener(
    "keydown", downListener, false
  );
  window.addEventListener(
    "keyup", upListener, false
  );

  // Detach event listeners
  key.unsubscribe = () => {
    window.removeEventListener("keydown", downListener);
    window.removeEventListener("keyup", upListener);
  };

  return key;
}
function hitTestSphere(r1: PIXI.Sprite, r2: PIXI.Sprite, radius: number) {

  //Define the variables we'll need to calculate
  let hit, combinedHalfWidths, combinedHalfHeights, vx, vy;

  //hit will determine whether there's a collision
  hit = false;

  //Find the center points of each sprite
  let r1centerX = r1.x + r1.width / 2;
  let r1centerY = r1.y + r1.height / 2;
  let r2centerX = r2.x + r2.width / 2;
  let r2centerY = r2.y + r2.height / 2;

  //Find the half-widths and half-heights of each sprite
  let r1halfWidth = r1.width / 2;
  let r1halfHeight = r1.height / 2;
  let r2halfWidth = r2.width / 2;
  let r2halfHeight = r2.height / 2;

  //Calculate the distance vector between the sprites
  vx = r1centerX - r2centerX;
  vy = r1centerY - r2centerY;
  let r = Math.sqrt(vx ** 2 + vy ** 2);
  return r < radius;
};

function hitTestRectangle(r1: PIXI.Sprite, r2: PIXI.Sprite) {

  //Define the variables we'll need to calculate
  let hit, combinedHalfWidths, combinedHalfHeights, vx, vy;

  //hit will determine whether there's a collision
  hit = false;

  //Find the center points of each sprite
  let r1centerX = r1.x + r1.width / 2;
  let r1centerY = r1.y + r1.height / 2;
  let r2centerX = r2.x + r2.width / 2;
  let r2centerY = r2.y + r2.height / 2;

  //Find the half-widths and half-heights of each sprite
  let r1halfWidth = r1.width / 2;
  let r1halfHeight = r1.height / 2;
  let r2halfWidth = r2.width / 2;
  let r2halfHeight = r2.height / 2;

  //Calculate the distance vector between the sprites
  vx = r1centerX - r2centerX;
  vy = r1centerY - r2centerY;

  //Figure out the combined half-widths and half-heights
  combinedHalfWidths = r1halfWidth + r2halfWidth;
  combinedHalfHeights = r1halfHeight + r2halfHeight;

  //Check for a collision on the x axis
  if (Math.abs(vx) < combinedHalfWidths) {

    //A collision might be occurring. Check for a collision on the y axis
    if (Math.abs(vy) < combinedHalfHeights) {

      //There's definitely a collision happening
      hit = true;
    } else {

      //There's no collision on the y axis
      hit = false;
    }
  } else {

    //There's no collision on the x axis
    hit = false;
  }

  //`hit` will be either `true` or `false`
  return hit;
};

function randomCountByDay(inital: number, day: number): number {
  return Math.max(randomInt(0, 1), inital - day)
}

export {
  loadProgressHandler, randomInt, keyboard, keyClass, hitTestRectangle, hitTestSphere , randomCountByDay,
  Item, generateString
}