import * as PIXI from "pixi.js";

export class MoveSprite extends PIXI.Sprite {
    vy: number
    vx: number
    constructor(texture: PIXI.Texture, vx: number = 0, vy: number = 0){
        super(texture);
        this.vx = vx;
        this.vy = vy;
    }
}